
.imageContainer{
    flex-grow: 1;
    padding: 20px;
    text-align: center;
}

.dropZone{
    border: 2px dashed;
    border-radius: 20px;
    padding: 20px;
    cursor: pointer;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 4px 28px 66px -49px rgba(0,0,0,0.69)
}

.uploadedImage{
    max-width: 100%;
    max-height: 100%;
}
.cloudIcon{
    padding-left: 10px;
    margin-bottom: 10px;
}

.dropZoneError{
    border: 2px dashed #d32f2f;
    border-radius: 20px;
    padding: 20px;
    cursor: pointer;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 4px 28px 66px -49px rgba(0,0,0,0.69)
}